import React, { useContext } from "react";
import { Context } from "../../store/appContext";
import ListEmpresa from "../Empresa/listEmpresa";
import { Row } from "reactstrap";
const Empresa = () => {
  const { store } = useContext(Context);

  return (
    <React.Fragment>
      <div className="">
        <div className="card overflow-scroll">
          <Row className="row g-0 text-center bg-base text-white min1500">
            <div className="col">
              <p><strong>Company: </strong></p>
            </div>
            <div className="col">
              <p><strong>Legal ID: </strong></p>
            </div>
            <div className="col">
              <p><strong>Cedula Juridica: </strong></p>
            </div>
            <div className="col">
              <p><strong>Email Admin: </strong></p>
            </div>
            <div className="col">
              <p><strong>Phone: </strong></p>
            </div>
            <div className="col">
              <p><strong>Edit: </strong></p>
            </div>
          </Row>
          {store.empresa.map((item, index) => {
            return (
              <div key={index}>
                <ListEmpresa
                  id={index}
                  empresaID={item.empresaID}
                  nombre={item.nombre}
                  sociedad={item.sociedad}
                  cedula_juridica={item.cedula_juridica}
                  email_administracion={item.email_administracion}
                  telefono={item.telefono}
                />
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Empresa;
