const SidebarData = [
    {
        label: "Overview",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard"
    },
    // management
    {
        label: "Management",
        icon: "bx bx-wallet",
        subItem: [
            {
                label: "Balance PPH",
                icon: "fas fa-balance-scale",
                subItem: [
                    {
                        label: "Balance Buckeye",
                        icon: "fas fa-balance-scale",
                        subItem: [
                
                            { label: "Collections", link: "/balance-buckeye" }
                        ]
                    },
                    {
                        label: "Balance Jazz",
                        icon: "fas fa-balance-scale",
                        subItem: [
                           
                            { label: "Collections", link: "/balance-jazz" }
                        ]
                    },
                    {
                        label: "Balance Horizon",
                        icon: "fas fa-balance-scale",
                        subItem: [
                           
                            { label: "Collections", link: "/balance-horizon" }
                        ]
                    },
                    {
                        label: "Balance PPHCR",
                        icon: "fas fa-balance-scale",
                        subItem: [
                          
                            { label: "Collections", link: "/balance-pphcr" }
                        ]
                    }
        
                ]
            },
            {
                label: "Collections PPH",
                icon: "bx bx-wallet",
                subItem: [
                    {
                        label: "Collections PPH",
                        subItem: [
                          
                            { label: "Collections", link: "/collections" }
                        ]
                    },
                    {
                        label: "Collections WL",
                        subItem: [
                          
                            { label: "Collections", link: "/wl-collections" }
                        ]
                    },
                    {
                        label: "Collections %",
                        subItem: [
                           
                            { label: "Collections", link: "/percentage-collections" }
                        ]
                    },
                    {
                        label: "Collections Review",
                        subItem: [
                            
                            { label: "Collections", link: "/review-collections" }
                        ]
                    },
                    {
                        label: "Collections Affiliate",
                        subItem: [
                         
                            { label: "Collections", link: "/affiliate-collections" }
                        ]
                    }
        
                ]
            },    
            {
                label: "Sportsbook",
                icon: "bx bx-ball",
                subItem: [
                    {
                        label: "Players",
                        subItem: [
                            { label: "PLayers", link: "/players" }
                        ]
                    },
                    {
                        label: "Deposits",
                        subItem: [
                            { label: "Deposits", link: "/deposits" }
                        ]
                    },
                    {
                        label: "Withdrawals",
                        subItem: [
                            { label: "Withdrawals", link: "/withdrawals" }
                        ]
                    }
                ]
            },
            {
                label: "Casino",
                icon: "bx bx-ball",
                subItem: [
                    {
                        label: "Players",
                        subItem: [
                            { label: "PLayers", link: "/players-casino" }
                        ]
                    },
                    {
                        label: "Deposits",
                        subItem: [
                            { label: "Deposits", link: "/deposits-casino" }
                        ]
                    },
                    {
                        label: "Withdrawals",
                        subItem: [
                            { label: "Withdrawals", link: "/withdrawals-casino" }
                        ]
                    }
                ]
            },
            {
                label: "Reports",
                icon: "bx bx-list-check",
                subItem: [
                    {
                        label: "PPH Reports",
                        subItem: [
                            { label: "Reports", link: "/report-pph" }
                        ]
                    },
                    {
                        label: "WL Reports",
                        subItem: [
                            { label: "Reports", link: "/report-wl" },
                        ],
                    },
                    {
                        label: "% Reports",
                        subItem: [
                            { label: "Reports", link: "/report-percentage" },
                        ],
                    },
                    {
                        label: "Affiliate Reports",
                        subItem: [
                            { label: "Reports", link: "/report-affiliate" },
                        ],
                    },
                    {
                        label: "Review Reports",
                        subItem: [
                            { label: "Reports", link: "/report-review" },
                        ],
                    },
                    {
                        label: "Marketing Reports",
                        subItem: [
                            { label: "Reports", link: "/marketing-report" },
                        ],
                    }
                ]
            },   
            {
                label: "Customers",
                icon: "bx bx-wallet",
                subItem: [
                    {
                        label: "Agents PPH",
                        subItem: [
                            { label: "Agents", link: "/agents" }
                        ]
                    },
                    {
                        label: "Affiliates",
                        subItem: [
                            { label: "Affiliates", link: "/affiliates" }
                        ]
                    },
                    {
                        label: "Percentage",
                        subItem: [
                            { label: "Customer", link: "/customer-percentage" }
                        ]
                    },
                    {
                        label: "WL",
                        subItem: [
                           
                            { label: "Customer", link: "/wl-custumers" }
                        ]
                    },
                    {
                        label: "Review",
                        subItem: [
                       
                            { label: "Customer", link: "/review-custumers" }
                        ]
                    },
                    {
                        label: "Customer Follow Up",
                        subItem: [
                            { label: "Create Customer", link: "/create-custumer" },
                            { label: "Customers", link: "/customers" }
                        ]
                    }
                ]
            },
            {
                label: "Tickets",
                icon: "bx bx-comment-add",
                subItem: [
                    { label: "Create Ticket", link: "/create-ticket" },
                    { label: "Tickets", link: "/tickets" },
                ],
            },
            {
                label: "Templates",
                icon: "bx bx-file",
                subItem: [
                    { label: "Templates", link: "/template" },
                ],
            },
            {
                label: "Email",
                icon: "mdi mdi-email-outline",
                subItem: [
                    // { label: "Inbox", link: "/inbox" },
                    // { label: "Read Email", link: "/read-email" },
                    { label: "Email Compose", link: "/compose-email" },
                ],
            },
        ]
    },
    // company
    {
        label: "Company",
        icon: "bx bxs-buildings",
        subItem: [
            {
                label: "Basic Services",
                subItem: [
  
                    { label: "Services", link: "/supplies" }
                ]
            },
            {
                label: "Software Services",
                subItem: [
        
                    { label: "Services", link: "/software" }
                ]
            },
            {
                label: "Additional Payments",
                subItem: [
        
                    { label: "Payments", link: "/additional-payment" }
                ]
            },
            {
                label: "Company Inventory",
                subItem: [

                    { label: "Items", link: "/assets" }
                ]
            },
            {
                label: "Freelance",
                subItem: [
      
                    { label: "Freelance", link: "/freelance" }
                ]
            }
            ,
            {
                label: "Companies",
                subItem: [
    
                    { label: "Companies", link: "/company" }
                ]
            },
            {
                label: "Brands",
                icon: "bx bx-building",
                subItem: [
           
                    { label: "Brands", link: "/brands" },
                ],
            },
            {
                label: "Employee",
                icon: "bx bx-user",
                subItem: [
                    {
                        label: "Employee",
                        subItem: [
                           
                            { label: "Employees", link: "/employee" }
                        ]
                    },
                    {
                        label: "User permissions",
                        subItem: [
                          
                            { label: "User Permissions", link: "/user" }
                        ]
                    }
                ]
            },
        ]
    },

   
    {
        label: "Icons",
        icon: "ri-brush-line",
        subItem: [
            { sublabel: "Box Icons", link: "/icon-boxicon" },
            { sublabel: "Material Design", link: "/icons-materialdesign" },
            { sublabel: "Dripicons", link: "/icon-dripicons" },
            { sublabel: "Font Awesome", link: "/icons-fontawesome" },
        ],
    }
]


const filterSidebarData = (userPuesto) => {
    let filteredSidebarData = [];

    // Lógica de filtrado basada en el puesto del usuario
    switch (userPuesto) {
        case 'Admin':
            // Agregar aquí los elementos del menú para Admin
            filteredSidebarData = SidebarData.filter((item) =>
                ['Overview', 'Management', 'Company',/* ... Otros elementos para Admin */].includes(item.label)
            );
            break;
        case 'Marketing':
            // Agregar aquí los elementos del menú para Marketing
            filteredSidebarData = SidebarData.filter((item) =>
                [  /* ... Otros elementos para Marketing */].includes(item.label)
            );
            break;
        case 'Cs':
            // Agregar aquí los elementos del menú para Cs
            filteredSidebarData = SidebarData.filter((item) =>
                [ /* ... Otros elementos para CS */].includes(item.label)
            );
            break;
        case 'Collections':
            // Agregar aquí los elementos del menú para Collections
            filteredSidebarData = SidebarData.filter((item) =>
                [ /* ... Otros elementos para Collections */].includes(item.label)
            );
            break;
            case 'SB':
                // Agregar aquí los elementos del menú para Collections
                filteredSidebarData = SidebarData.filter((item) =>
                    [ /* ... Otros elementos para Collections */].includes(item.label)
                );
                break;
        // Agregar otros casos según los puestos
        default:
            // Si no coincide con ningún caso, se muestra un menú predeterminado
            filteredSidebarData = SidebarData.filter((item) =>
                [ /* ... Otros elementos predeterminados */].includes(item.label)
            );
            break;
    }

    return filteredSidebarData;
};

export { SidebarData, filterSidebarData };

