const getState = ({ getStore, getActions, setStore }) => {
	return {
		store: {
			empresa: [],
			activos: [],
			cobro: [],
			cobroWL: [],
			cobroPercentage: [],
			cobroReview: [],
			cobroAffiliate: [],
			balanceJazz: [],
			balanceBuckeye: [],
			balanceHorizon: [],
			balancePPHCR: [],
			marcas: [],
			agentes: [],
			clientesPercentage: [],
			wlCustomer: [],
			reviewCustomer: [],
			jugadores: [],
			jugadoresCasino: [],
			depositos: [],
			depositosCasino: [],
			retiros: [],
			retirosCasino: [],
			suministros: [],
			software: [],
			additionalPayments: [],
			reportesPPH: [],
			reportesWL: [],
			reportesPercentage: [],
			reportesReview: [],
			reportesAffiliate: [],
			marketingReportes: [],
			colaborador: [],
			users: [],
			templates: [],
			ingresos: [],
			afiliados: [],
			customerFollow: [],
			tiquetes: [],
			freelance: [],
		},
		actions: {
			loadEmpresa: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/empresa";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ empresa: results });
			},
			loadActivos: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/activo";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ activos: results });
			},
			loadCobro: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/cobrosPPH";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ cobro: results });
			},
			loadCobroWL: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/cobrosWL";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ cobroWL: results });
			},
			loadCobroPercentage: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/cobrosPercentage";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ cobroPercentage: results });
			},
			loadCobroReview: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/cobrosReview";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ cobroReview: results });
			},
			loadCobroAffiliate: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/cobrosAfilate";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ cobroAffiliate: results });
			},
			loadBalanceJazz: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/cobrosPPHJazz";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ balanceJazz: results });
			},
			loadBalanceBuckeye: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/cobrosPPHBuckeye";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ balanceBuckeye: results });
			},
			loadBalanceHorizon: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/cobrosPPHHorizon";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ balanceHorizon: results });
			},
			loadBalancePPHCR: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/cobrosPPHPHCR";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ balancePPHCR: results });
			},
			loadMarcas: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/marcas/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ marcas: results });
			},
			loadAgentes: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/agentes/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ agentes: results });
			},
			loadClientesPercentage: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/clientespercentage/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ clientesPercentage: results });
			},
			loadAfiliados: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/afiliado/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ afiliados: results });
			},
			loadWLCustomer: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/wlCustomer/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ wlCustomer: results });
			},
			loadReviewCustomer: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/reviewCustomer/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ reviewCustomer: results });
			},
			loadCustomerFollow: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/customerFollowUp/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ customerFollow: results });
			},
			loadJugadores: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/info_jugador/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ jugadores: results });
			},
			loadJugadoresCasino: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/info_jugador_casino/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ jugadoresCasino: results });
			},
			loadDepositos: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/depositos_jugadores/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ depositos: results });
			},
			loadDepositosCasino: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/depositos_jugadores_casino/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ depositosCasino: results });
			},
			loadRetiros: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/retiros/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ retiros: results });
			},
			loadRetirosCasino: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/retiros_casino/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ retirosCasino: results });
			},
			loadSuministros: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/pagos/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ suministros: results });
			},
			loadSoftware: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/software/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ software: results });
			},
			loadAditionalPayments: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/pagos_adicionales/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ additionalPayments: results });
			},
			loadReportesPPH: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/reportesPPH/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ reportesPPH: results });
			},
			loadReportesWL: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/reportesWL/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ reportesWL: results });
			},
			loadReportesPercentage: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/reportesPercentage/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ reportesPercentage: results });
			},
			loadReportesReview: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/reportesReview/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ reportesReview: results });
			},
			loadReportesAffiliate: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/reportesAfiliate/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ reportesAffiliate: results });
			},
			loadMarketingReportes: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/reporte_marketing/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ marketingReportes: results });
			},
			loadColaborador: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/colaborador/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ colaborador: results });
			},
			loadUsers: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/usuario/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ users: results });
			},
			loadTemplates: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/templates/";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ templates: results });
			},
			loadIngresos: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/ingreso";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ ingresos: results });
			},
			loadTiquetes: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/tiquetes";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ tiquetes: results });
			},
			loadFreelance: async () => {
				const url = "https://crmsbapp-7f82716e70c9.herokuapp.com/freelance";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ freelance: results });
			},
			changename: username => {
				let rs = JSON.parse( localStorage.getItem('my_token'));
				if(!rs){

				}else{
					setStore({ username_temp: rs.username });
				}
			}
		}
	};
};

export default getState;
