import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../store/appContext";
import emailjs from '@emailjs/browser';

// Form Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
    Card,
    CardBody,
    Col,
    Row,
    Container
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";

const EmailCompose = () => {
    document.title = "Email Compose  | CRM SUCCOR - React Admin & Dashboard Template";

    const { store } = useContext(Context);

    const templates = [
        {
            vipPerHead: "template_2iwlqna",
            empire: "template_mb9qtkn",
            bookies: "template_uo8jeea",
            american: "template_3y0lums",
            mexico: "template_ixhvy5c",
            whalers: "template_kn2p7im",
            kingdom: "template_m0n9mpi",
            goat: "template_hc3jrci",
            pphcr: "template_6z8cpvp",
            alpha: "template_kt2suab",
        }
    ]
    const services = [
        {
            vipPerHead: "service_4xmlg5h",
            empire: "service_j8wlpkk",
            bookies: "service_y8z0x78",
            american: "service_si1dgsc",
            mexico: "service_diwvm2t",
            whalers: "service_cugasmt",
            kingdom: "service_amfw5un",
            goat: "service_6uflixn",
            pphcr: "service_pw6nj2u",
            alpha: "service_8z3glyi",
        }
    ]
    const agentesPPH = ["Choose One"];
    store.agentes.forEach((i) => {
        agentesPPH.push(i.agencia); // Almacena el nombre si cumple con la condición
    });
    
    
    const [toSend, setToSend] = useState({
        from_name: '',
        to_name: '',
        message: '',
        to_email: '',
        Subject: ''
    });
    
    let updatedEmail = '';
    let agency = '';
    let updteSercive = '';

    const [emailTempl,setEmailTempl] = useState('');
    const [service,setService] = useState('');

    useEffect(() => {
        store.agentes.forEach((index) => {
            if (index.agencia === toSend.to_name) {
                updatedEmail = index.email;
                agency = index.PPH_asignado;
            }
        });
        
        if (updatedEmail !== toSend.to_email) {
            setToSend(prevState => ({
                ...prevState,
                to_email: updatedEmail
            }));
        }

        const mapAgencyToTemplate = {
            "Vip Pay Per Head": "vipPerHead",
            "Pay Per Head Empire": "empire",
            "Bookies Per Head": "bookies",
            "American Per Head": "american",
            "Pay Per Head Mexico": "mexico",
            "Pay Per Head Whalers": "whalers",
            "Pay Per Head Kingdom": "kingdom",
            "Pay Per Head Goat": "goat",
            "Price Per Head CR": "pphcr",
            "Alpha Per Head": "alpha",
        };

        const mapAgencyToSercive = {
            "Vip Pay Per Head": "vipPerHead",
            "Pay Per Head Empire": "empire",
            "Bookies Per Head": "bookies",
            "American Per Head": "american",
            "Pay Per Head Mexico": "mexico",
            "Pay Per Head Whalers": "whalers",
            "Pay Per Head Kingdom": "kingdom",
            "Pay Per Head Goat": "goat",
            "Price Per Head CR": "pphcr",
            "Alpha Per Head": "alpha",
        };
        const template = mapAgencyToTemplate[agency];
        const serv = mapAgencyToSercive[agency];
        if (template) {
            setEmailTempl(templates[0][template]);
        }

        if (serv) {
            setService(services[0][serv]);
        }
        
    }, [toSend.to_name, store.agentes, toSend.to_email],emailTempl,service);
    
    const sendEmail = (e) => {
        e.preventDefault();
        
        emailjs.send( service, emailTempl , toSend, 'gLZmbPr8_P0yjdklu')
        .then((result) => {
            console.log(result.text);
            alert("Message sent Successfully");
            }, (error) => {
                alert('Message could not be Sent');;
                console.log(error.text);
            });
            
        };
        
        const handleChange = (e) => {
            setToSend({ ...toSend, [e.target.name]: e.target.value });
        };
        
        console.log(service + ' service');
        console.log(toSend)
        return (
            <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Email" breadcrumbItem="Email Compose" />
                    <Row className="mb-4">
                        <Col xl={12}>
                            <Card className="mb-0">
                                <CardBody>
                                    <form method="post" onSubmit={sendEmail}>
                                        <div className="mb-0">
                                            <div className="mb-3">
                                                <div className="col-12 fw-bold">To</div>
                                                <select className="form-select" name="to_name" aria-label="to_name" onChange={handleChange} >
                                                    {
                                                        agentesPPH.map((index) => {
                                                            return (
                                                                <option key={index} name="to_name" value={index} >{index}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>

                                            <div className="mb-3">
                                                <input type="text" className="form-control" name="Subject" placeholder="Subject" value={toSend.Subject} onChange={handleChange} required/>
                                            </div>
                                            <div id="email-editor" style={{ minHeight: "360px" }}>
                                                <textarea
                                                    className="form-control"
                                                    placeholder="Start From Here..."
                                                    name="message"
                                                    value={toSend.message}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="btn-toolbar">
                                                <div className="">
                                                    <button type="submit" className="btn btn-info waves-effect waves-light"> <span>Send</span> <i className="fab fa-telegram-plane ms-2"></i> </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EmailCompose;