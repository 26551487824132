import React, { useContext } from "react";
import ListAditionalPayments from "./listAditionalPayment";
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";

export default function RecordAditionalPayments() {
    const { store } = useContext(Context);
    return (
        <React.Fragment>
            <div className="page-content">
                <h2>Additional Payment</h2>
                <Row className="g-0 overflow-scroll">
                    <Row className="row g-0 text-center bg-base text-white py-3 min1500">
                        <div className="col-3 lines">Supplier</div>
                        <div className="col-3 lines">Service</div>
                        <div className="col-2 lines">Amount</div>
                        <div className="col-2 lines">Type of Payment</div>
                    </Row>

                    {
                        store.additionalPayments.map((item, index) => {
                            return (
                                <div key={index} className="col-12">
                                    <ListAditionalPayments
                                        id={index}
                                        pagoID={item.pagoID}
                                        provedor={item.provedor}
                                        nombre={item.nombre}
                                        pagos={item.pagos}
                                        tipo={item.tipo}
                                    />
                                </div>
                            );
                        })}
                </Row>
            </div>
        </React.Fragment >
    )
}
